import React from "react";
import Link from "next/link";
import { routes } from "lib/routes";
import { styles } from "./Anchor.styles";
import type { AnchorProps } from "./Anchor.types";

const AnchorComponent = (
  {
    routeName,
    params,
    children,
    newTab = false,
    testID,
    onClick,
    onWebClick,
    nested = false,
    disabled = false,
    ...rest
  }: AnchorProps & { testID?: string },
  ref: React.ForwardedRef<HTMLAnchorElement>,
) => {
  const href =
    routeName === "Redirect"
      ? params.url
      : {
          pathname: routes[routeName].path,
          query: Object.entries(params ?? {}).reduce<Record<string, string>>(
            (params, [key, value]) => {
              return value ? { ...params, [key]: value } : params;
            },
            {},
          ),
        };

  const InnerLink = (): React.ReactElement => (
    <Link href={href ?? "#"} passHref legacyBehavior>
      <a
        {...rest}
        data-testid={testID}
        ref={ref}
        css={[styles.baseAnchor, nested && styles.nestedAnchor]}
        target={newTab ? "_blank" : "_self"}
        onClick={(event) => {
          onClick?.();
          onWebClick?.(event);
        }}
      >
        {!nested && children}
      </a>
    </Link>
  );
  if (disabled) {
    return <>{children}</>;
  }

  if (!nested) {
    return <InnerLink />;
  }

  return (
    <div style={styles.container}>
      <InnerLink />
      <div style={styles.nestedContainer}>{children}</div>
    </div>
  );
};

export const Anchor = React.forwardRef(AnchorComponent);
