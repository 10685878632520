import { withHOC } from "lib/hoc/withHOC";
import { withServerSideCatalogue } from "lib/hoc/withServerSideCatalogue";
import { withWebLayout } from "lib/hoc/withWebLayout";
import { withCatalogueServerSideProps } from "lib/serverSideProps/withCatalogue";
import { withServerSideProps } from "lib/serverSideProps/withServerSideProps";
import { withUserServerSideProps } from "lib/serverSideProps/withUser";
import { Book } from "screens/Book";

export const getServerSideProps = withServerSideProps([
  withCatalogueServerSideProps,
  withUserServerSideProps,
]);

export default withHOC([withServerSideCatalogue, withWebLayout], Book);
