import React from "react";
import { View } from "react-native";
import {
  Heading,
  Text,
  Icon,
  theme,
  useResponsive,
} from "@clearabee/ui-library-native";
import { NeedHelp } from "components/Composite/NeedHelp/NeedHelp";
import { Trustpilot } from "components/Trustpilot/Trustpilot";
import { Container } from "components/Container/Container";
import { styles, ids, contentWrapperStyles } from "./Layout.styles";
import { useTranslation } from "react-i18next";
import { Anchor } from "components/Core/Anchor/Anchor";
import { LayoutProps } from "./Layout.types";
import { createResponsiveStyles } from "@clearabee/ui-library";

export const Layout = ({
  title,
  backTo,
  contentWidth,
  children,
  styles: userStyles,
  leftElement,
}: LayoutProps): React.ReactElement => {
  const [translate] = useTranslation();
  const {
    screens: { medium },
  } = useResponsive();
  const { styles: contentStyles, ids: contentWrapperIds } =
    contentWrapperStyles(contentWidth);

  return (
    <>
      <Container
        styles={[userStyles, !!leftElement && { flex: 1 }, { zIndex: 2 }]}
      >
        <View style={{ flexDirection: "row", flex: 1 }}>
          {leftElement}
          <View style={{ flex: 1 }}>
            <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
              {backTo && (
                <div style={{ width: "fit-content" }}>
                  <Anchor {...backTo}>
                    <View style={styles.backTo} dataSet={{ media: ids.backTo }}>
                      <View
                        style={{
                          transform: [{ rotate: "180deg" }],
                        }}
                      >
                        <Icon.Chevron color="accent" size="small" />
                      </View>
                      <Text
                        styles={{ marginLeft: theme.spacing.xsmall }}
                        color="light"
                      >
                        {translate("common.back")}
                      </Text>
                    </View>
                  </Anchor>
                </div>
              )}
              <View
                style={[
                  styles.headingWrapper,
                  !backTo && { marginTop: theme.fontDefaults.base.lineHeight },
                ]}
                dataSet={{ media: ids.headingWrapper }}
              >
                <Heading
                  level={4}
                  styles={styles.title}
                  dataSet={{ media: ids.title }}
                >
                  {title}
                </Heading>

                {medium && (
                  <View
                    style={styles.trustpilot}
                    dataSet={{ media: ids.trustpilot }}
                  >
                    <Trustpilot color="light" />
                  </View>
                )}
              </View>
            </View>
            {leftElement && (
              <View
                style={[contentStyles.contentWrapper, { paddingHorizontal: 0 }]}
                dataSet={{ media: contentWrapperIds.contentWrapper }}
              >
                {children}
              </View>
            )}
          </View>
        </View>
      </Container>
      {!leftElement && (
        <View
          style={contentStyles.contentWrapper}
          dataSet={{ media: contentWrapperIds.contentWrapper }}
        >
          {children}
        </View>
      )}
      <div
        css={createResponsiveStyles({
          base: {
            position: "relative",
            bottom: -1,
          },
          medium: {
            background: `linear-gradient(180deg, #ffffff00 0%, #ffffff00 50%, ${theme.colors.light.base} 50%)`,
          },
        })}
      >
        <Container>
          <View
            style={styles.needHelpContainer}
            dataSet={{ media: ids.needHelpContainer }}
          >
            <NeedHelp />
          </View>
        </Container>
      </div>
      {!medium && (
        <View style={styles.trustpilot} dataSet={{ media: ids.trustpilot }}>
          <Trustpilot color="light" />
        </View>
      )}
    </>
  );
};
