import { StyleSheet } from "@clearabee/ui-library";

export const styles = StyleSheet({
  container: {
    position: "relative",
  },
  baseAnchor: {
    textDecoration: "none",
    pointerEvents: "all",
    height: "100%",
  },
  nestedAnchor: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  nestedContainer: {
    position: "relative",
    zIndex: 1,
    pointerEvents: "none",
  },
});
