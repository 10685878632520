import ResponsiveStyleSheet from "react-native-media-query";
import { createResponsiveStyles, theme } from "@clearabee/ui-library-native";

export const { styles, ids } = ResponsiveStyleSheet.create({
  bannerContainer: createResponsiveStyles({
    large: {
      position: "relative",
    },
  }),
  infoSection: createResponsiveStyles({
    base: {
      display: "flex",
      flexDirection: "column",
      paddingVertical: theme.spacing.xlarge2,
      alignItems: "center",
    },
    medium: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    large: {
      width: "100%",
      paddingHorizontal: theme.spacing.xlarge,
    },
  }),
  bookSection: createResponsiveStyles({
    base: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing.medium,
    },
    small: {
      flexDirection: "row",
    },
    medium: {
      marginTop: 0,
    },
  }),
  infoText: createResponsiveStyles({
    base: {
      padding: 1,
      textAlign: "center",
    },
    medium: {
      textAlign: "left",
    },
  }),
});
