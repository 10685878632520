import React from "react";
import { Platform, View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  FlexGrid,
  CTA,
  formatCurrency,
  useResponsive,
  Icon,
  LinkText,
  Text,
  Heading,
  theme,
} from "@clearabee/ui-library-native";
import {
  getFromPrice,
  useCatalogue,
  getItemDescendants,
} from "@clearabee/ui-sdk";
import { Layout } from "components/Layout/Layout";
import { SEO } from "components/Seo/Seo";
import { Anchor } from "components/Core/Anchor/Anchor";
import { styles, ids } from "./Book.styles";
import { Image } from "components/Image/Image";
import { hideFromPrices } from "lib/utils";
import { SERVICES } from "lib/constants/services";

export const Book = (): React.ReactElement => {
  const [translate] = useTranslation();
  const { defaultCatalogue: catalogue } = useCatalogue();
  const { screens } = useResponsive();

  const Wrapper = ({ children }: { children: React.ReactNode[] }) => {
    if (screens.small) {
      return (
        <View style={styles.children} dataSet={{ media: ids.children }}>
          {children}
        </View>
      );
    }

    return (
      <View style={styles.children}>
        <FlexGrid rowSpacing="xsmall2">
          {children.map((child, index) => (
            <FlexGrid.Cell
              key={index}
              base="50%"
              style={{
                justifyContent: "center",
                paddingHorizontal: theme.spacing.small,
              }}
            >
              {child}
            </FlexGrid.Cell>
          ))}
        </FlexGrid>
      </View>
    );
  };

  return (
    <>
      <SEO title={translate("hwa.screens.book.title")} />
      <Layout title={translate("hwa.screens.book.title")}>
        <FlexGrid
          cellSpacing={screens.xlarge ? "xsmall" : "small"}
          rowSpacing={screens.medium ? "small" : "xsmall2"}
        >
          {SERVICES.filter(
            ({ sku }) =>
              sku !== "" &&
              catalogue.items.find((item) => item.sku === sku)?.active,
          ).map((item) => (
            <FlexGrid.Cell
              key={item.service}
              base="100%"
              small="50%"
              xlarge="20%"
            >
              <Anchor
                routeName="ServicePage"
                params={{ categorySku: item.sku }}
                testID={item.buttonTestId}
                nested
              >
                <CTA
                  styles={styles.ctaContainer}
                  shadow={Platform.OS === "web"}
                  heading={
                    <Heading
                      styles={styles.heading}
                      dataSet={{ media: ids.heading }}
                      fontSize="xlarge"
                      numberOfLines={2}
                    >
                      {item.service}
                    </Heading>
                  }
                  pill={
                    item.sku === "MANVAN"
                      ? translate("common.samedayAvailable").toUpperCase()
                      : undefined
                  }
                  orientation={screens.small ? "portrait" : "landscape"}
                  subheading={
                    <>
                      <View style={styles.subheadingContainer}>
                        <View style={styles.subheadingMain}>
                          {hideFromPrices(item.sku) ? (
                            <Text fontSize="small" styles={styles.boldText}>
                              {translate("common.getPrices")}
                            </Text>
                          ) : (
                            <>
                              <Text fontSize="small" styles={styles.boldText}>
                                {translate("common.from")}{" "}
                                {formatCurrency(
                                  getFromPrice(catalogue.items, (items) =>
                                    getItemDescendants(item.sku, items).filter(
                                      ({ price }) => price,
                                    ),
                                  ),
                                )}
                              </Text>
                              <Text fontSize="small">
                                &nbsp;{translate("common.incVat")}
                              </Text>
                            </>
                          )}
                        </View>
                      </View>
                    </>
                  }
                  textColor="brand"
                  image={
                    <Image
                      source={item.image}
                      resizeMode="contain"
                      style={styles.CTAImage}
                    />
                  }
                  background="light"
                  button={{
                    children: translate("common.bookNow"),
                  }}
                >
                  <>
                    {screens.medium && (
                      <Text
                        styles={styles.suitableFor}
                        dataSet={{ media: ids.suitableFor }}
                      >
                        {translate("common.suitableFor")}:
                      </Text>
                    )}
                    <Wrapper>
                      {[
                        ...item.useCases.map((useCase) => (
                          <View
                            key={useCase}
                            style={styles.subheadingCopyHeading}
                            dataSet={{ media: ids.subheadingCopyHeading }}
                          >
                            <View style={styles.tickWrap}>
                              <Icon.Tick2 size="xsmall" color="light" />
                            </View>
                            <Text fontSize="small" styles={styles.marginLeft}>
                              {useCase}
                            </Text>
                          </View>
                        )),
                        <Anchor
                          routeName="Redirect"
                          newTab
                          params={{ url: item.readMore }}
                          key={item.sku}
                        >
                          <View
                            style={styles.andMore}
                            dataSet={{ media: ids.andMore }}
                          >
                            <LinkText underline>
                              {translate("common.andMore")}
                            </LinkText>
                          </View>
                        </Anchor>,
                      ]}
                    </Wrapper>
                  </>
                </CTA>
              </Anchor>
            </FlexGrid.Cell>
          ))}
        </FlexGrid>
      </Layout>
    </>
  );
};
