import ResponsiveStylesheet from "react-native-media-query";
import { createResponsiveStyles, theme } from "@clearabee/ui-library-native";

export const { styles, ids } = ResponsiveStylesheet.create({
  ctaContainer: {
    width: "100%",
  },
  heading: createResponsiveStyles({
    base: {
      color: theme.colors.brand.base,
      textAlign: "center",
      fontFamily: "ProximaBold",
      maxWidth: 200,
    },
    medium: {
      textAlign: "center",
    },
  }),
  subheadingContainer: {
    display: "flex",
    alignItems: "center",
  },
  subheadingMain: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  boldText: {
    fontFamily: "ProximaBold",
  },
  marginLeft: {
    marginLeft: theme.spacing.xsmall,
  },
  subheadingUseCasesContainer: {
    display: "flex",
  },
  subheadingCopyHeading: createResponsiveStyles({
    base: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
  }),
  andMore: createResponsiveStyles({
    base: {
      alignItems: "flex-start",
      marginLeft: theme.spacing.medium,
    },
    small: {
      marginTop: theme.spacing.xsmall,
      alignItems: "center",
      marginLeft: 0,
    },
    medium: {
      paddingBottom: theme.spacing.medium,
    },
  }),
  suitableFor: {
    marginVertical: theme.spacing.small,
    fontFamily: "ProximaBold",
  },
  children: createResponsiveStyles({
    base: {
      paddingHorizontal: theme.spacing.medium,
      marginBottom: 0,
      alignItems: "center",
      marginHorizontal: "auto",
    },
    small: {
      paddingHorizontal: 0,
    },
  }),
  CTAImage: {
    width: "95%",
    height: "95%",
  },
  tickWrap: {
    backgroundColor: theme.colors.positive.base,
    padding: theme.spacing.xsmall2,
    borderRadius: theme.spacing.large,
  },
});
