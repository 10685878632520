import React from "react";

type HigherOrderComponent = (Page: React.ComponentType) => React.ComponentType;

export const withHOC = (
  higherOrderComponents: HigherOrderComponent[],
  Component: React.ComponentType,
) => {
  let chain = Component;

  for (const hoc of higherOrderComponents) {
    chain = hoc(chain);
  }

  return chain;
};
