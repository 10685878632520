import React from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import { Heading, Panel, theme } from "@clearabee/ui-library-native";
import { ButtonLink } from "components/Core/ButtonLink/ButtonLink";
import { styles, ids } from "./NeedHelp.styles";
import { getEnv } from "lib/getEnv";

export const NeedHelp = (): React.ReactElement => {
  const [translate] = useTranslation();

  return (
    <View style={styles.bannerContainer}>
      <Panel
        color="accent"
        shadow={false}
        styles={styles.infoSection}
        dataSet={{ media: ids.infoSection }}
      >
        <View
          style={{
            display: "flex",
          }}
        >
          <Heading
            level={3}
            styles={[
              styles.infoText,
              { color: theme.colors.brand.base, paddingBottom: 5 },
            ]}
            dataSet={{ media: ids.infoText }}
          >
            {translate("hwa.components.needHelp.title")}
          </Heading>
          <Heading
            level={6}
            styles={[styles.infoText, { color: theme.colors.dark.base }]}
            dataSet={{ media: ids.infoText }}
          >
            {translate("hwa.components.needHelp.subtitle")}
          </Heading>
        </View>

        <View style={styles.bookSection} dataSet={{ media: ids.bookSection }}>
          <ButtonLink
            routeName="Redirect"
            params={{
              url: `${getEnv("WEBSITE_URL")}/contact`,
            }}
            color="greyscale"
            size="medium"
            newTab
          >
            {translate("common.contactUs")}
          </ButtonLink>
        </View>
      </Panel>
    </View>
  );
};
