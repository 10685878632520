import ResponsiveStyleSheet, { NamedStyles } from "react-native-media-query";
import { createResponsiveStyles, theme } from "@clearabee/ui-library-native";

export const { styles, ids } = ResponsiveStyleSheet.create({
  wrap: createResponsiveStyles({
    base: {
      marginBottom: theme.spacing.large,
    },
    medium: {
      marginTop: theme.spacing.xlarge,
    },
    large: {
      marginTop: theme.spacing.large,
      marginBottom: theme.spacing.small,
    },
  }),
  headingWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: createResponsiveStyles({
    base: {
      marginBottom: theme.spacing.medium,
      color: theme.colors.accent.base,
      marginTop: theme.spacing.medium,
      height: 0,
      textAlign: "center",
    },
    medium: {
      textAlign: "left",
    },
  }),
  trustpilot: createResponsiveStyles({
    base: {
      alignItems: "center",
      marginBottom: theme.spacing.xlarge2,
    },
    medium: {
      alignItems: "flex-end",
      marginTop: -theme.spacing.large,
      marginBottom: 0,
    },
  }),
  backTo: {
    flexDirection: "row",
    alignItems: "center",
    color: theme.colors.light.base,
  },
  needHelpContainer: createResponsiveStyles({
    base: {
      marginBottom: theme.spacing.xlarge,
    },
    medium: {
      marginBottom: 0,
    },
  }),
});

type ContentWrapperStyles<
  UserStyles = NamedStyles<{ contentWrapper: Record<string, unknown> }>,
> = (width?: keyof typeof theme.screens) => {
  styles: UserStyles;
  ids: Record<keyof UserStyles, string>;
};

export const contentWrapperStyles: ContentWrapperStyles = (
  width?: keyof typeof theme.screens,
) => {
  return ResponsiveStyleSheet.create({
    contentWrapper: createResponsiveStyles({
      base: {
        marginBottom: theme.spacing.xlarge,
        zIndex: 1,
        paddingHorizontal: theme.spacing.small,
      },
      medium: {
        marginTop: theme.spacing.large,
      },
      large: {
        width: "100%",
        maxWidth: width ? theme.screens[width] : theme.screens.xlarge,
        marginHorizontal: "auto",
        flex: 1,
      },
    }),
  });
};
