import React from "react";
import { ActivityIndicator, ActivityIndicatorProps } from "react-native";
import {
  ColorKeyVariants,
  getColorFromVariantKey,
} from "@clearabee/ui-library-base";

interface SpinnerProps extends ActivityIndicatorProps {
  color?: ColorKeyVariants;
}

export const Spinner = ({
  color = "dark",
  ...props
}: SpinnerProps): React.ReactElement => {
  return (
    <ActivityIndicator
      testID="spinner"
      {...props}
      color={getColorFromVariantKey(color)}
    />
  );
};
