import React from "react";
import {
  Trustpilot as TrustpilotComponent,
  TrustpilotProps as UiLibTrustpilotProps,
} from "@clearabee/ui-library-native";
import { useTrustpilot, UseTrustPilotReturn } from "@clearabee/ui-sdk";
import { getEnv } from "lib/getEnv";

type TrustpilotProps = Omit<UiLibTrustpilotProps, "reviews" | "score">;

const defaultValues: UseTrustPilotReturn = {
  reviews: 20000,
  score: 4.9,
};

export const Trustpilot = (props: TrustpilotProps): React.ReactElement => {
  const key = getEnv("TRUSTPILOT_API_KEY") ?? "";
  const hook = useTrustpilot(key, defaultValues);

  return <TrustpilotComponent {...hook} {...props} />;
};
